import React, {useContext, useState} from 'react'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import Modal from 'src/components/modal/Modal'
import PrimaryButton from 'src/components/buttons/Primary'
import Input from 'src/components/forms/Input'
import { Error } from 'src/components/forms'
import { useVisitorApi } from 'src/hooks/useApi'
import { isValidVAT } from 'src/util'

const BOX = "flex items-center border py-4 px-4 md:py-8 md:px-12 rounded transition-all duration-200 mb-4 shadow-md";
const ACTIVE = "border-dark-blue-600";
const NOT_ACTIVE = "border-grey-500";
const TITLE = "font-semibold text-lg mb-3";
const DESC = "text-grey-600";
const INNER_BOX = "pl-6";

export default function ChangePaymentModal({modalActive, setModalActive}) {
    const {t} = useTranslation();
    const {state: {profile: {teacher_profile}}} = useContext(ProfileContext);
    const { updatePaymentMethod } = useVisitorApi();
    const [error, setError] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(teacher_profile.payment_method);
    const [vat, setVAT] = useState(teacher_profile.vat)

    async function validate() {
        if(paymentMethod === 'invoice' && !vat) {
            return setError(true)
        } else if (!isValidVAT(vat)) {
            return setError(true)
        }
        await updatePaymentMethod({paymentMethod, vat})
        setModalActive(false)
    }

    const radioOptions = [
        {
            id: 1,
            value: 'invoice',
            label: t('teacher.register.paymentOptions.option1'),
            desc:  t('teacher.register.invoiceBody')
        },
        {
            id: 2,
            value: 'performance_note',
            label: t('teacher.register.paymentOptions.option2'),
            desc: t('teacher.register.expenseNoteBody')
        },
    ];

    return (
        <Modal active={modalActive} onClickClose={() => setModalActive(!modalActive)} className="max-w-screen-lg w-full lg:p-16 overflow-y-scroll">
            <h2 className="mb-8 text-3xl font-bold">{t('teacher.account.pricing.payments')}</h2>
            <p className="mb-6 text-lg">{t('teacher.account.pricing.howToGetPaid')}</p>
            {radioOptions.map(payment => (
                <label className={classNames(BOX, 'cursor-pointer', paymentMethod === payment.value ? ACTIVE : NOT_ACTIVE)} key={payment.id}>
                    <input
                        type="radio"
                        checked={paymentMethod === payment.value}
                        onChange={() => setPaymentMethod(payment.value)}/>
                    <div className={classNames(INNER_BOX)}>
                        <p className={classNames(TITLE)}>{payment.label}</p>
                        <p className={classNames(DESC)}>{payment.desc}</p>
                        {payment.value === 'invoice' &&
                        <div className="w-full lg:w-1/3 mt-3">
                            <Input
                                isRequired
                                name="vat"
                                value={vat}
                                label="VAT"
                                onChange={({target}) => setVAT(target.value)}
                            />
                            { error && <Error>{t('teacher.account.pricing.errorVAT')}</Error>}
                        </div>
                        }
                    </div>
                </label>
            ))}
            <PrimaryButton className="w-full md:w-auto mt-4" onClick={validate}>{t('teacher.account.pricing.savePayment')}</PrimaryButton>
        </Modal>
    )
}
