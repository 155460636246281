import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import Lesson from 'src/components/sections/teacher/upcomingLessons/Lesson'
import RescheduleModal from 'src/components/sections/teacher/upcomingLessons/RescheduleModal'
import Breadcrumbs from 'src/components/sections/teacher/upcomingLessons/Breadcrumbs'
import Clock from 'src/components/icons/Clock'
import { useVisitorApi } from "../../../../hooks/useApi";
import Success from "../../../forms/Success";
import {OutlinedButton} from "../../../buttons/Primary";

export default function UpcomingLessons({setActiveTab}) {
    const {t} = useTranslation();
    const [modalActive, setModalActive] = useState(false);
    const { getTeacherLessons } = useVisitorApi();
    const [modalData, setModalData] = useState(null);
    const [lessonsFilter, setLessonsFilter] = useState('all');
    const [lessonsToDisplay, setLessonsToDisplay] = useState([]);
    const [successMsg, setSuccessMsg] = useState(false);
    const [page, setPage] = useState(1);
    const [loadingPagination, setLoadingPagination] = useState(false);

    function openModal(lesson) {
        setModalData(lesson);
        setModalActive(!modalActive);
    }

    function hideModal () {
        setSuccessMsg(true)
        setModalData(null);
        setModalActive(false);
    }

    function fetchTeacherLessons(obj) {
        getTeacherLessons(obj).then((response) => {
            setLoadingPagination(false);
            setLessonsToDisplay(obj.page === 1 ? response.data :lessonsToDisplay.concat(response.data))
        })
    }

    function removeDuplicateDates(lessons) {
        let array = [];
        lessons.map((lesson) => {
            const date = DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).toISODate()
            if(!array.includes(date)){
                array.push(date)
            }
        });
        return array
    }

    function loadMore () {
        setLoadingPagination(true)
        setPage(page + 1);
    }

    useEffect(() => {
        if (lessonsFilter === 'all') {
            const before = null;
            const after = DateTime.local().startOf('day').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
            fetchTeacherLessons({before: null, after: after, page: page})
        }
        else {
            setPage(1);
            const before = lessonsFilter.endOf('day').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
            const after = lessonsFilter.startOf('day').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
            fetchTeacherLessons({before: before, after: after, page: page})
        }
    }, [lessonsFilter, modalActive, page]);

    return (
        <>
            <div>
                <div className="flex justify-between items-center flex-wrap">
                    <h2 className="w-full md:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark text-2xl lg:text-4xl">
                        {t('teacher.account.upcomingLessons.upcomingLessons')}
                    </h2>
                    <div onClick={() => setActiveTab('pastLessons')} className="flex items-center  cursor-pointer">
                        <Clock color="rgba(36, 48, 94, 1)"/>
                        <span className="pl-2 text-dark-blue-600">{t('student.profile.header.past')}</span>
                    </div>
                </div>
                <Breadcrumbs lessonsList={removeDuplicateDates(lessonsToDisplay)} lessonsFilter={lessonsFilter} setLessonsFilter={setLessonsFilter}/>
                {lessonsToDisplay.length ? lessonsToDisplay.map((lesson, i) => (
                    <Lesson
                        lesson={lesson}
                        key={i}
                        onClickReschedule={openModal}
                    />
                )) : (
                    <p className="mb-8 text-dark">{t('student.profile.lesson.noPlanned')}</p>
                )}
                {lessonsToDisplay.length > 0 &&
                <div className="flex justify-center mt-4">
                    <OutlinedButton
                        onClick={loadMore}
                        isLoading={loadingPagination}
                    >
                        {t('general.loadMore')}
                    </OutlinedButton>
                </div>
                }
                {successMsg && <Success className="">{t('student.profile.lesson.rescheduled')}</Success>}
            </div>
            <RescheduleModal hideModal={hideModal} setModalActive={setModalActive} modalActive={modalActive} modalData={modalData}/>
        </>
    )
}
