import React from 'react'
import classNames from 'classnames'

export default function Badge({ children, type, className }) {
    const DEFAULT = classNames(
        'inline-block px-3 py-1 rounded-full',
        'text-xs font-semibold',
    );
    const conditionalClasses = {
        'bg-alt-color-600 text-white': type === 'error',
        'bg-success text-white' : type === 'success'
    };

    return (
        <span className={classNames(DEFAULT, conditionalClasses, className)}>
            {children}
        </span>
    )
}
