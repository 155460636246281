import React from 'react'

export default function Zoom({ color, w }) {
    const size = w ?? 64;
    const width = size;
    const height = size / 1.83;

    return (
        <svg width={width} height={height} viewBox="0 0 64 34.9" fill={color || '#ffffff'}>
            <path d="M44.6,32.5c0,1.3-1.1,2.4-2.4,2.4H7.4c-4.1,0-7.4-3.3-7.4-7.5V2.4C0,1.1,1.1,0,2.4,0h34.8
                c4.1,0,7.4,3.3,7.4,7.5V32.5z M61.3,33.2l-12-7.7c-0.5-0.3-0.8-0.9-0.8-1.5v-13c0-0.6,0.3-1.2,0.8-1.5l12-7.7C62.4,1,64,1.8,64,3.2
                v28.4C64,33.1,62.4,33.9,61.3,33.2z" />
        </svg>
    )
}
