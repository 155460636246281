import React, { useContext } from 'react'
import Flatpickr from 'react-flatpickr'
import {Context as AddAvailability} from 'src/context/AddAvailabilityContext';
import { DateTime } from 'luxon'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { usePageContext } from 'src/context/PageContext'
import 'flatpickr/dist/themes/light.css'
import 'src/components/forms/DatePicker.css'

function DatePicker (props) {
    const {
        fetchAvailabilities,
        onChange,
        chosenDay,
    } = props;

    const { state: { toRender, dates }} = useContext(AddAvailability);
    const { lang } = usePageContext();

    function setLocale() {
        switch (lang) {
            case 'nl':
                return Dutch
            default:
                return null
        }
    }

    function updateDate(date) {
        onChange(DateTime.fromJSDate(date[0]))
    }

    function changeMonth(date, dStr, fp) {
        const after = DateTime.fromObject({ year: fp.currentYear, month: fp.currentMonth + 1}).startOf('month').plus({hours: 6}).toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
        const before = DateTime.fromObject({ year: fp.currentYear, month: fp.currentMonth + 1}).endOf('month').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
        fetchAvailabilities(before, after)
    }

    return (
        <div className="date-picker">
            <Flatpickr
                value={chosenDay?.toISO()}
                className="form-field"
                options={{
                    altInput: true,
                    altFormat: 'j F Y',
                    minDate: 'today',
                    inline: true,
                    selectedDates: toRender,
                    locale: {
                        ...setLocale(),
                        firstDayOfWeek: 1
                    }
                }}
                onDayCreate={(dObj, dStr, fp, dayElem) => {
                    if(dates.includes(DateTime.fromJSDate(dayElem.dateObj).toISODate()))
                        dayElem.classList.add('marked-date')
                }}
                onChange={updateDate}
                onMonthChange={changeMonth}
            />
        </div>
    )
}

export default DatePicker
