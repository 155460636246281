import React, { useReducer, useContext, useState } from 'react'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import PaymentMethod from 'src/components/sections/teacher/pricing/PaymentMethod'
import TrialPricing from 'src/components/sections/teacher/pricing/TrialPricing'
import PricingForm from 'src/components/sections/teacher/pricing/PricingForm'
import { initialPlans } from 'src/data/listData'
import { formatPricingListToReducer } from 'src/util'
import Success from 'src/components/forms/Success'
import { useTranslation } from 'react-i18next'

const pricingReducer = (state, action) => {
    switch (action.type) {
        case 'change_plan':
            return state.map(plan => plan.index === action.payload.index ? action.payload : plan );
        case 'add_trial':
            return [...state, action.payload];
        case 'remove_trial':
            return state.filter(plan => !plan.is_trial)
        case 'change_price':
            const { lessonPrice, duration } = action.payload
            const targetedDuration = state.filter(plan => plan.lesson_duration_minutes === duration)
            targetedDuration.map(lesson => {
                lesson.lesson_price_eur = lessonPrice
                return lesson
            })
            const otherDuration = state.filter(plan => plan.lesson_duration_minutes !== duration)
            return [...targetedDuration, ...otherDuration]
        default:
            return state
    }
}

export default function PricingLayout() {
    const { state: { profile: { teacher_profile }}} = useContext(ProfileContext);
    const [state, dispatch] = useReducer(pricingReducer, initialPriceList());
    const [successMsg, setSuccessMsg] = useState(false);
    const { updatePrices } = useVisitorApi();
    const { t } = useTranslation();

    function initialPriceList() {
        if(teacher_profile.lesson_plans.length) {
            return formatPricingListToReducer(teacher_profile.lesson_plans)
        }

        return initialPlans
    }

    function savePrices() {
        updatePrices(state).then(response => {
            setSuccessMsg(response)
            setTimeout(() => setSuccessMsg(false), 3000)
        })
    }


    return (
        <>
            <PaymentMethod/>
            <TrialPricing state={state} isActive={state.find((plan) => plan.is_trial === true)} dispatch={dispatch}/>
            <PricingForm state={state} dispatch={dispatch} onSubmit={savePrices}/>
            {successMsg && <Success>{t('teacher.account.pricing.pricingUpdated')}</Success>}
        </>
    )
}
