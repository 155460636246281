import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'src/components/modal/Modal'
import TextArea from 'src/components/forms/Textarea'
import {SubmitButton} from 'src/components/buttons/Primary'
import useForm from "../../../../hooks/useForm";
import {useVisitorApi} from "../../../../hooks/useApi";
import {Error} from "../../../forms";

export default function RescheduleModal({modalActive, setModalActive,modalData, hideModal}) {
    const { t } = useTranslation();
    const { rescheduleLessonTeacher } = useVisitorApi();
    const initialValues = {
        reason: '',
    };

    const {
        values,
        onChangeValue,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: hideModal,
        onFail: () => console.log('fail'),
        validationMessages: t('teacher.account.reschedule.globalError'),
    });

    async function onSubmit(values) {
        return await rescheduleLessonTeacher(modalData.id, {
            ...values
        });
    }


    return (
        <Modal active={modalActive} onClickClose={() => setModalActive(!modalActive)}
               className="max-w-screen-lg lg:w-1/2 lg:p-16 overflow-y-scroll">
            <h2 className="mb-8 text-3xl font-titles">{t('teacher.account.reschedule.rescheduleLesson')}</h2>
            <div className="flex flex-col">
                <p className="mb-6">{t('teacher.account.reschedule.personalMsg')}</p>
            </div>
            <form
                onSubmit={submit}
            >
                <TextArea
                    label="Message"
                    maxCount={500}
                    name={'reason'}
                    placeholder="Message"
                    value={values.reason}
                    error={errors.reason}
                    onChange={onChangeValue}
                />
                { errors.general && <Error>{errors.general}</Error>}
                <SubmitButton isLoading={isLoading} className="mt-4">{t('teacher.account.reschedule.rescheduleLesson')}</SubmitButton>
            </form>
        </Modal>
    )
}
