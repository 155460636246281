import React, {useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import Input from 'src/components/forms/Input'
import {Error} from 'src/components/forms'
import {calculatePricingDiscount} from '../../../../../util/pricing'

const FIRST_COL = "w-6/12 md:w-2/12";
const SEC_COL = "w-6/12 md:w-4/12";
const DEFAULT_COL = "px-4 py-2 md:p-4";
const COL = "w-6/12 md:w-3/12";
const INTRO = "mb-3";
const ROW = "border border-grey-300 hover:bg-grey-300 hover:border-0 flex justify-start items-center flex-wrap md:flex-nowrap";
const INFO = 'bg-grey-500 rounded-full h-4 w-4 text-white text-xs inline-flex items-center justify-center mb-3 ml-2';

export default function LessonPlans(props) {
    const {
        list,
        dispatch,
        title,
        initialPrice,
        duration,
        commission,
        vat
    } = props;

    const { t } = useTranslation();
    const [lessonPrice, setLessonPrice] = useState(initialPrice);
    const [error, setError] = useState(false);
    const { state: { profile: { teacher_profile }}} = useContext(ProfileContext);

    const [, ...rest] = list;
    function changePrice(value, duration) {
        if (+value < (duration === 60 ? 35 : 25)) setError(true)
        if (+value >= (duration === 60 ? 35 : 25)) setError(false)
        setLessonPrice(value)
    }

    const tooltipMsg = t('teacher.account.pricing.toolTipMsg');

    useEffect(() => {
        dispatch({
            type: 'change_price',
            payload: {
                lessonPrice,
                duration
            }
        })
    }, [lessonPrice, dispatch])

    function calculatePriceForClient() {
        const price = +lessonPrice + (+lessonPrice * (commission / 100));

        return price.toFixed(2)
    }

    function calculateWhambamCommission () {
        const price = (+lessonPrice * (commission / 100));
        return price.toFixed(2)
    }

    function calculateWhambamCommissionWithDiscount(plan) {
        const newPrice = (+lessonPrice * (plan.discount_percentage / 100));
        const price =  calculateWhambamCommission () - (+newPrice * (commission / 100));
        return price.toFixed(2)
    }

    function calculateWithBTW(plan) {
        const newPrice = (lessonPrice * plan.lesson_amount ) - (+lessonPrice * plan.lesson_amount * (plan.discount_percentage / 100));
        const discount = calculateWhambamCommissionWithDiscount(plan) * plan.lesson_amount;
        let newTotal = newPrice + discount;
        newTotal = +newTotal + (+newTotal * (vat / 100));
        return newTotal.toFixed(2)
    }

    function calculateBTW() {
        let price = calculatePriceForClient();
        price = +price + (+price * (vat / 100));

        return price.toFixed(2)
    }

    return (
        <div className="mt-8">
            <h3 className="text-xl font-bold my-4">{title}</h3>
            <div className="table-auto border border-grey-300 rounded w-full">
                <div className={classNames(ROW)}>
                    <div className={classNames(DEFAULT_COL, FIRST_COL)}>
                        1 {t('teacher.account.pricing.lesson')}
                    </div>
                    <div className={classNames(DEFAULT_COL, SEC_COL)}>
                        {t('teacher.account.pricing.priceTeacherWithoutVat')}
                        <div className="md:w-2/3 mt-1">
                            <Input
                                type='number'
                                isRequired
                                name="vat"
                                value={lessonPrice}
                                onChange={({target}) => changePrice(target.value, duration)}
                            />
                        </div>
                        {error && <Error>{duration <= 30 ? t('teacher.account.pricing.priceTooLow30') : t('teacher.account.pricing.priceTooLow60')}</Error>}
                    </div>
                    <div className={classNames(DEFAULT_COL, COL)}>
                        <div className="flex items-center">
                            <p className={classNames(INTRO)}>{t('teacher.account.pricing.priceForWhambam')}</p>
                        </div>
                        <p>€ {calculateWhambamCommission()}</p>
                    </div>
                    <div className={classNames(DEFAULT_COL, COL)}>
                        <p className={classNames(INTRO)}>{t('teacher.account.pricing.paymentInclBTW')}</p>
                        <p>€ {calculateBTW()}</p>
                    </div>
                </div>
                {rest.map(plan => (
                    <div key={plan.index} className={classNames(ROW)}>
                        <div className={classNames(DEFAULT_COL, FIRST_COL)}>
                            {plan.lesson_amount} {t('teacher.account.pricing.lessons')}
                        </div>
                        <div className={classNames(DEFAULT_COL, SEC_COL)}>
                            {t('teacher.account.pricing.discount')}
                            <div className="md:w-2/3 mt-1 flex items-center">
                                <Input
                                    type='number'
                                    isRequired
                                    name="vat"
                                    value={plan.discount_percentage}
                                    onChange={(e) => {
                                        if(e.target.value <= calculatePricingDiscount(plan.lesson_amount)) {
                                            plan.discount_percentage = +e.target.value
                                            dispatch({
                                                type: 'change_plan',
                                                payload: plan
                                            })
                                        }
                                    }}
                                />
                                <span className="pl-2 font-semibold text-grey-800">%</span>
                            </div>
                        </div>
                        <div className={classNames(DEFAULT_COL, COL)}>
                            <div className="flex items-center">
                                <p className={classNames(INTRO)}>{t('teacher.account.pricing.priceForWhambam')}</p>
                            </div>
                            <p>€ {calculateWhambamCommissionWithDiscount(plan)}</p>
                        </div>
                        <div className={classNames(DEFAULT_COL, COL)}>
                            <p className={classNames(INTRO)}>{t('teacher.account.pricing.paymentInclBTW')}</p>
                            <p>€ {calculateWithBTW(plan)}</p>
                        </div>
                    </div>
                ))}
            </div>
            <ReactTooltip type='light'/>
        </div>
    )
}
