import React from 'react'
import { useTranslation } from 'react-i18next'

const INTRO = "md:text-lg text-grey-600";

export default function UnderReview() {
    const { t } = useTranslation();

    return (
        <div className="flex justify-between items-center flex-col h-full md:mt-10">
            <h2 className="w-full lg:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark-blue-600 text-2xl lg:text-4xl text-center">{t('teacher.account.inReview.title')}</h2>
            <div className="md:my-6 my-2">
                <p className={INTRO}>{t('teacher.account.inReview.introLine1')}</p>
                <p className={INTRO}>{t('teacher.account.inReview.introLine2')}</p>
            </div>
            <a
                href='/' target="_blank" rel="noopener"
                className="text-2xl text-dark-blue-600 font-bold">
                {t('teacher.account.inReview.cta')}
            </a>
        </div>
    )
}
