import React from 'react'
import { useTranslation } from 'react-i18next'
import LessonPlans from 'src/components/sections/teacher/pricing/form/LessonPlans'
import PrimaryButton from 'src/components/buttons/Primary'

const WHAMBAM_COMMISSION = 20;
const VAT = 21;

export default function PricingForm({state, dispatch, onSubmit}) {
    const { t } = useTranslation();
    let thirtyMinLessons = state.filter(lesson => lesson.lesson_duration_minutes === 30);
    let sixtyMinLessons = state.filter(lesson => lesson.lesson_duration_minutes === 60);
    let initialPriceThirty = thirtyMinLessons.length ? thirtyMinLessons[0].lesson_price_eur : 25;
    let initialPriceSixty = sixtyMinLessons.length ? sixtyMinLessons[0].lesson_price_eur : 35;

    return (
        <div>
            <div className="flex justify-between">
                <div>
                    <h2 className="w-full lg:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark text-2xl lg:text-4xl">{t('teacher.account.pricing.pricing')}</h2>
                </div>
                <div>
                    <PrimaryButton onClick={onSubmit}>{t('teacher.account.pricing.updatePrices')}</PrimaryButton>
                </div>
            </div>
            <LessonPlans
                list={thirtyMinLessons}
                dispatch={dispatch}
                title={t('teacher.account.pricing.30minLesson')}
                initialPrice={initialPriceThirty}
                duration={30}
                commission={WHAMBAM_COMMISSION}
                vat={VAT}
            />
            <LessonPlans
                list={sixtyMinLessons}
                dispatch={dispatch}
                title={t('teacher.account.pricing.60minLesson')}
                initialPrice={initialPriceSixty}
                duration={60}
                commission={WHAMBAM_COMMISSION}
                vat={VAT}
            />
        </div>
    )
}
