import React from 'react'

export default function ArrowCompact({ width, height, color }) {
  return (
    <svg width={width || 27} height={height || 18} viewBox="0 0 27 18" fill={color || 'rgba(36, 48, 94, 1)'}>
        <path d="M9.6,0.9c0.5,0.5,0.6,1.4,0.1,2L9.6,3.1L5.1,7.5H25c0.8,0,1.5,0.7,1.5,1.5
            c0,0.8-0.6,1.4-1.4,1.5l-0.1,0H5.1l4.4,4.4c0.5,0.5,0.6,1.4,0.1,2l-0.1,0.1c-0.5,0.5-1.4,0.6-2,0.1l-0.1-0.1l-7-7
            c-0.5-0.5-0.6-1.4-0.1-2l0.1-0.1l7-7C8,0.4,9,0.4,9.6,0.9z" />
    </svg>
  )
}
