import React, { useState } from 'react'
import 'src/components/forms/Toggle.css'

export default function Toggle({ name, id, isChecked, onChange }) {
    const [checked, setChecked] = useState(isChecked);

    function handleChange(e) {
        setChecked(!checked);
        onChange(e.target.checked);
    }

    return (
        <div className="toggle">
            <input
                id={id}
                type="checkbox"
                name={name}
                checked={checked}
                onChange={handleChange}
                className="toggle-input"
            />
            <label htmlFor={id} className="toggle-label">
                <span className="toggle-control" />
                <span className="toggle-bg" />
            </label>
        </div>
    )
}
