import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import Lesson from 'src/components/sections/teacher/pastLessons/Lesson'
import Clock from 'src/components/icons/Clock'
import { useVisitorApi } from "../../../../hooks/useApi";
import {OutlinedButton} from "../../../buttons/Primary";

export default function PastLessons({setActiveTab}) {
    const {t} = useTranslation();
    const { getTeacherLessons } = useVisitorApi();
    const [lessonsToDisplay, setLessonsToDisplay] = useState([]);
    const [page, setPage] = useState(1);
    const [loadingPagination, setLoadingPagination] = useState(false);

    function fetchTeacherLessons(obj) {
        getTeacherLessons(obj).then((response) => {
            setLoadingPagination(false)
            setLessonsToDisplay(obj.page === 1 ? response.data :lessonsToDisplay.concat(response.data))
        })
    }

    useEffect(() => {
        const before = DateTime.local().startOf('day').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
        const after = DateTime.local(2020, 7, 1).toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
        fetchTeacherLessons({before: before, after: after, page: page})
    }, [page]);

    function loadMore () {
        setLoadingPagination(true)
        setPage(page + 1);
    }

    return (
        <>
            <div>
                <div className="flex justify-between items-center flex-wrap">
                    <h2 className="w-full md:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark text-2xl lg:text-4xl">
                        {t('teacher.account.pastLessons.pastLessons')}
                    </h2>
                    <div onClick={() => setActiveTab('upcomingLessons')} className="flex items-center cursor-pointer">
                        <Clock color="rgba(36, 48, 94, 1)"/>
                        <span className="pl-2 text-dark-blue-600">{t('teacher.account.nav.upcomingLessons')}</span>
                    </div>
                </div>
                {lessonsToDisplay.length ? lessonsToDisplay.map((lesson, i) => (
                    <Lesson
                        lesson={lesson}
                        key={i}
                    />
                )) : (
                    <>
                        <p className="my-8 text-dark">{t('teacher.account.pastLessons.noPast')}</p>
                    </>
                )}
                {lessonsToDisplay.length > 0 &&
                <div className="flex justify-center mt-4">
                    <OutlinedButton
                        onClick={loadMore}
                        isLoading={loadingPagination}
                    >
                        {t('general.loadMore')}
                    </OutlinedButton>
                </div>
                }
            </div>
        </>
    )
}
