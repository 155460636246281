import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Toggle from 'src/components/forms/Toggle'

const DEFAULT_TRIAL = {
    "lesson_amount": 1,
    "lesson_duration_minutes": 15,
    "lesson_price_eur": "10",
    "discount_percentage": 0,
    "is_trial": true
};

export default function TrialPricing({ dispatch, isActive }) {
    const { t } = useTranslation();
    const [trial, setTrial] = useState(isActive)

    function toggleTrial(boolean) {
        setTrial(boolean)
        if(boolean) {
            return dispatch({
                type: 'add_trial',
                payload: DEFAULT_TRIAL
            })
        }
        return dispatch({
            type: 'remove_trial'
        })
    }

    return (
        <div className="my-8">
            <h2 className="w-full lg:w-auto mb-2 font-bold lg:mb-2 font-titles text-dark text-2xl lg:text-4xl">{t('teacher.account.pricing.trialLesson')}</h2>
            <p>{t('teacher.account.pricing.trialExplanation')}</p>
            <div className="p-4 md:p-8 bg-grey-300 flex justify-between items-center mt-4">
                <div>
                    <p className="font-semibold text-lg mb-2">{t('teacher.account.pricing.trialLesson')}</p>
                    <p className="text-lg">€ 10</p>
                </div>
                <Toggle
                    name="trial"
                    id="trial"
                    isChecked={trial}
                    onChange={() => toggleTrial(!trial)}
                />
            </div>
        </div>
    )
}
