import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import {OutlinedButton} from 'src/components/buttons/Primary'
import ChangePaymentModal from 'src/components/sections/teacher/pricing/ChangePaymentModal'

export default function PricingLayout() {
    const {t} = useTranslation();
    const {state: {profile: {teacher_profile}}} = useContext(ProfileContext);
    const [modalActive, setModalActive] = useState(false);

    return (
        <>
            <h2 className="w-full lg:w-auto mb-2 font-bold lg:mb-4 font-titles text-dark text-2xl lg:text-4xl">{t('teacher.account.pricing.payments')}</h2>
            <div className="p-4 md:p-8 bg-grey-300 flex justify-between flex-wrap">
                <div>
                    <p className="font-semibold text-lg">{t('teacher.account.pricing.youChoseFor')} {t(`teacher.account.pricing.${teacher_profile.payment_method}`)} {t('teacher.account.pricing.asPayment')}</p>
                    {teacher_profile.payment_method === 'invoice' &&
                    <p className="text-grey-600 mt-2">BTW: {teacher_profile.vat}</p>
                    }
                </div>
                <div className="w-full md:w-auto mt-4 md:mt-0">
                    <OutlinedButton className="bg-grey-300 w-full md:w-auto" onClick={() => setModalActive(true)}>{t('teacher.account.pricing.change')}</OutlinedButton>
                </div>
            </div>
            <ChangePaymentModal modalActive={modalActive} setModalActive={setModalActive}/>
        </>
    )
}
