import React, {useContext, useEffect, useState} from 'react'
import {Context as ThemeContext} from 'src/context/ThemeContext'
import usePrivateRoute from 'src/hooks/usePrivateRoute'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import {Context as ProfileContext} from '../../context/ProfileContext'
import NavBar from 'src/components/sections/teacher/Navbar'
import EditAccount from "src/components/sections/teacher/account/EditAccount";
import EditProfile from 'src/components/sections/teacher/profile/EditProfile'
import AvailabilityModal from 'src/components/sections/teacher/profile/AvailabilityModal'
import UpcomingLessons from 'src/components/sections/teacher/upcomingLessons/UpcomingLessons'
import PricingLayout from 'src/components/sections/teacher/pricing/PricingLayout'
import UnderReview from 'src/components/sections/teacher/underReview/UnderReview'
import ConnectZoom from 'src/components/sections/teacher/zoom/Zoom'
import { useTranslation } from 'react-i18next'
import PastLessons from "../../components/sections/teacher/pastLessons/PastLessons";
import {useVisitorApi} from "../../hooks/useApi";

export default function TeacherAccount(props) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('editAccount');
    const [modalActive, setModalActive] = useState(false);
    const {setTheme} = useContext(ThemeContext);
    const {state: {profile}} = useContext(ProfileContext);
    const { getMe } = useVisitorApi();

    usePrivateRoute();
    useEffect(() => setTheme('inverse'), []);

    useEffect(function getUser(){
        getMe()
    }, []);

    function switchComponents() {
        switch (activeTab) {
            case 'editAccount' :
                return <EditAccount setActiveTab={setActiveTab}/>
            case 'upcomingLessons':
                return <UpcomingLessons setActiveTab={setActiveTab}/>
            case 'pastLessons':
                return <PastLessons setActiveTab={setActiveTab}/>
            case 'teacherProfile':
                return <EditProfile openModal={setModalActive}/>
            case 'pricing':
                return <PricingLayout/>
            case 'zoom':
                return <ConnectZoom />
            default:
                return <UpcomingLessons />
        }
    }

    if (profile) {

        return (
            <MarketingLayout {...props} title={profile.first_name} showTopBanner={!profile.teacher_profile.approved_at} topBanner={t('teacher.account.inReview.introLine1')}>
                <div className="px-4 overflow-x-hidden">
                    <div className="container mx-auto py-8 lg:py-12 flex items-start flex-wrap">
                        <NavBar
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            user={profile}
                        />
                        <div className="w-full lg:w-8/12">
                            {profile.teacher_profile.approved_at ?
                                switchComponents()
                                :
                                <UnderReview />
                            }
                        </div>
                    </div>
                </div>
                <AvailabilityModal modalActive={modalActive} setModalActive={setModalActive}/>
            </MarketingLayout>
        )
    }
    return null
}
