export function calculatePricingDiscount(lesson_amount) {
    if(lesson_amount === 3) {
        return 10
    } else if(lesson_amount === 5) {
        return 15
    } else if(lesson_amount === 10) {
        return 20
    }

    return 5
}
