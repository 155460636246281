import React from 'react'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import ArrowCompact from 'src/components/icons/ArrowCompact'

const DEFAULT = 'inline-block py-4 bg-grey-300 text-dark-blue-600 cursor-pointer flex-grow-0 flex-shrink-0 text-center';
const ACTIVE = 'inline-block text-white bg-dark-blue-600 py-4 cursor-pointer flex-grow-0 flex-shrink-0 text-center';

function ArrowBtn({children, disabled}) {
    return (
        <div className="px-2">
            <button className={`w-12 h-12 rounded-full shadow transform hover:scale-95 focus:outline-none flex items-center justify-center bg-white transition-all duration-150 ${disabled && 'opacity-50'}`}>
                {children}
            </button>
        </div>
    )
}

export default function Breadcrumbs({lessonsList, lessonsFilter, setLessonsFilter }) {
    const {t} = useTranslation();

    function checkIfActive(identifier) {
        if(identifier === 'all') {
            return lessonsFilter === identifier ? ACTIVE : DEFAULT;
        } else if( lessonsFilter instanceof DateTime) {
            return lessonsFilter.toFormat('LLL dd yyyy') === identifier ? ACTIVE : DEFAULT;
        }
        return DEFAULT
    }

    return (
        <div className="my-4 relative">
            <div className="flex flex-wrap overflow-hidden max-w-full w-full">
                <div className="w-full md:w-auto flex-grow-0 flex-shrink-0 mb-4">
                    <span
                        className={classNames(checkIfActive('all'), 'mr-2 px-6')}
                        onClick={() => setLessonsFilter('all')}>
                        {t('teacher.account.upcomingLessons.all')}
                    </span>
                    <span
                        className={classNames(checkIfActive(DateTime.local().setLocale('us').toFormat('LLL dd yyyy')), 'mr-2 px-6')}
                        onClick={() => setLessonsFilter(DateTime.local().setLocale('us'))}>
                        {t('teacher.account.upcomingLessons.today')}
                    </span>
                </div>
                <div className="w-full md:w-9/12 mb-4">
                    <Carousel
                        draggable={false}
                        slidesPerPage={3}
                        slidesPerScroll={3}
                        breakpoints={{
                            767: {
                                slidesPerPage: 1,
                                slidesPerScroll: 1
                            }
                        }}
                        arrowRight={<ArrowBtn><div style={{ transform: 'rotate(180deg)' }}><ArrowCompact width={16} height={16} /></div></ArrowBtn>}
                        arrowRightDisabled={<ArrowBtn disabled><div style={{ transform: 'rotate(180deg)' }}><ArrowCompact width={16} height={16} /></div></ArrowBtn>}
                        arrowLeft={<ArrowBtn><ArrowCompact width={16} height={16} /></ArrowBtn>}
                        arrowLeftDisabled={<ArrowBtn disabled><ArrowCompact width={16} height={16} /></ArrowBtn>}
                        addArrowClickHandler
                    >
                    {lessonsList.map((lesson, i) => (
                        <div className="w-full px-1" key={i}>
                            <span
                                className={classNames(checkIfActive(DateTime.fromISO(lesson, {zone: 'gmt'}).setLocale('us').toFormat('LLL dd yyyy')), 'w-full')}
                                onClick={() => setLessonsFilter(DateTime.fromISO(lesson, {zone: 'gmt'}).setLocale('us'))}
                            >
                                {DateTime.fromISO(lesson, {zone: 'gmt'}).setLocale('us').toFormat('LLL dd yyyy')}
                            </span>
                        </div>
                    ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
