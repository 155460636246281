import React, {useContext, useState} from 'react'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import Edit from 'src/components/icons/Edit'
import SignOut from 'src/components/icons/SignOut'
import {DefaultAvatarLogo} from 'src/components/atoms/DefaultAvatar'
import { Link } from 'gatsby'
import useLocalizedRoutes from "../../../hooks/useLocalizedRoutes";

const LINK = 'block py-2 md:py-3 px-4 lg:px-10 border-l-4 border-white font-semibold md:w-full md:w-auto cursor-pointer';
const ACTIVE = 'border-dark-blue-600 text-dark-blue-600';

export default function NavBar({user, activeTab, setActiveTab}) {
    const {t} = useTranslation();
    const {forgetProfile} = useContext(ProfileContext);
    const [imgError, setImgError] = useState(false);
    const { editTeacherAccount } = useLocalizedRoutes();

    function checkIfActiveTab(name) {
        if (name === activeTab) return ACTIVE;
        return null
    }
    return (
        <div className="w-full lg:w-4/12 lg:pr-12 xl:pr-20 lg:sticky">
            <div className="border border-grey-300 rounded mb-6 lg:mb-0 md:flex lg:block">
                <div
                    className="px-4 py-2 lg:p-4 md:p-10 md:border-r lg:border-r-0 lg:border-b border-grey-300 md:w-1/2 lg:w-full flex flex-col items-center">
                    <div className="mb-3">
                        {imgError ?
                            <DefaultAvatarLogo/> :
                            <img
                                src={user.teacher_profile.profile_picture_url}
                                onError={() => setImgError(true)}
                                alt={user.first_name}
                                className="w-32 h-32 sm:h-40 sm:w-40 lg:h-56 lg:w-56 object-cover rounded-full"
                            />
                        }
                    </div>
                    <h2 className="font-titles text-2xl lg:text-3xl font-bold text-dark">{user.first_name}</h2>
                </div>
                <div className="py-4 lg:py-8 md:w-1/2 lg:w-full md:flex md:items-center flex-wrap">
                    <div
                        className={classNames(LINK, checkIfActiveTab('editAccount') )}
                        onClick={() => setActiveTab('editAccount')}
                    >{t('teacher.account.nav.editAccount')}</div>
                    <div
                        className={classNames(LINK, checkIfActiveTab('upcomingLessons') )}
                        onClick={() => setActiveTab('upcomingLessons')}
                    >{t('teacher.account.nav.upcomingLessons')}</div>
                    <div
                        className={classNames(LINK, checkIfActiveTab('pastLessons') )}
                        onClick={() => setActiveTab('pastLessons')}
                    >{t('teacher.account.nav.pastLessons')}</div>
                    <div
                        className={classNames(LINK, checkIfActiveTab('teacherProfile') )}
                        onClick={() => setActiveTab('teacherProfile')}
                    >{t('teacher.account.nav.teacherProfile')}</div>
                    <div
                        className={classNames(LINK, checkIfActiveTab('pricing') )}
                        onClick={() => setActiveTab('pricing')}
                    >{t('teacher.account.nav.pricing')}</div>
                    <div
                        className={classNames(LINK, checkIfActiveTab('zoom') )}
                        onClick={() => setActiveTab('zoom')}
                    >{t('teacher.account.nav.zoom')}</div>
                    <div
                        className="lg:hidden text-grey-500 font-semibold inline-flex items-center pl-5 mt-2"
                        onClick={forgetProfile}
                    >
                        <SignOut/>
                        <span className="pl-2">{t('student.profile.nav.signout')}</span>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block p-4 md:py-6 md:px-10 cursor-pointer" onClick={forgetProfile}>
                <div
                    className="text-grey-500 font-semibold inline-flex items-center pl-1"
                >
                    <SignOut/>
                    <span className="pl-2">{t('student.profile.nav.signout')}</span>
                </div>
            </div>
        </div>
    )
}
