import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import Clock from 'src/components/icons/Clock'
import Calendar from 'src/components/icons/Calendar'
import Badge from 'src/components/atoms/Badge'

export default function Lesson({ lesson, onClickReschedule }) {
    const { t, i18n } = useTranslation();
    return (
        <div className="my-6 border border-grey-500 rounded-sm p-4 lg:p-8">
            <div className="flex lg:items-center flex-start">
                <div className="flex-1">
                    <div className="flex items-center justify-between flex-wrap">
                        <h2 className="text-2xl lg:text-3xl font-titles font-bold text-dark mr-3">{`${lesson.booking.student.first_name} ${lesson.booking.student.last_name}`}</h2>
                        {lesson.canceled && (
                            <Badge
                                className="mb-2"
                                type="error"
                            >{t('student.profile.lesson.canceled')}</Badge>
                        )}
                    </div>
                    <div className="flex flex-wrap w-full">
                        <div className="flex-1">
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.booking.instrument.translations[i18n.language]}</p>
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.booking.student.experience_level}</p>
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.progression}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center pt-8">
                <div className="mr-8 inline-flex items-center">
                    <Calendar color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl').toFormat('LLL dd yyyy')}</span>
                </div>
                <div className="inline-flex items-center">
                    <Clock color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')} - {DateTime.fromISO(lesson.ends_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')}</span>
                </div>
            </div>
            {lesson.booking.comments !== "" &&
            <div className="w-10/12 mt-8">
                <p className="text-xs">{t('teacher.account.upcomingLessons.comment')}</p>
                <p className="text-grey-800 mt-2">{lesson.booking.comments}</p>
            </div>
            }
        </div>
    )
}
