import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {Context as ProfileContext} from 'src/context/ProfileContext'
import {Context as AddAvailability} from 'src/context/AddAvailabilityContext'
import {Context as RemoveAvailability} from 'src/context/RemoveAvailabilityContext'
import {useVisitorApi} from 'src/hooks/useApi'
import Modal from 'src/components/modal/Modal'
import TimeslotsTeacher from 'src/components/sections/teacher/profile/TimeslotsTeacher'
import DatePicker from 'src/components/sections/teacher/profile/DatePicker'
import {formatAvailabilitiesObject} from 'src/util'
import {SubmitButton} from 'src/components/buttons/Primary'
import 'src/components/forms/DatePicker.css'

const AFTER = DateTime.utc().startOf('month').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");
const BEFORE = DateTime.utc().endOf('month').toFormat("kkkk-LL-dd'T'HH:mm:ssZZ");

export default function AvailabilityModal({modalActive, setModalActive}) {
    const {t} = useTranslation();
    const {addTeacherAvailabilities, deleteTeacherAvailabilities, getTeacherAvailabilities} = useVisitorApi();
    const {state: {profile: {teacher_profile: {id}}}} = useContext(ProfileContext);
    const [chosenDay, setChosenDay] = useState(DateTime.local().startOf('day'));
    const {state: {toAdd, availabilityObj}, setExisting, setAvailabilityObj, resetToAdd} = useContext(AddAvailability);
    const {state, resetToRemove} = useContext(RemoveAvailability);
    const [loading, setLoading] = useState(false);

    // Fetch and set availabilities for the current month in the calendar
    async function fetchAvailabilities(before = BEFORE, after = AFTER) {
        const res = await getTeacherAvailabilities({id, before, after});
        setAvailabilityObj(res)
        setExisting(formatAvailabilitiesObject(res))
    }

    useEffect(() => {
        if(modalActive) {
            fetchAvailabilities()
        }
    }, [modalActive])

    async function onSubmit() {
        setLoading(true)
        if (state.length) {
            const listToDelete = availabilityObj.filter(av => state.includes(av.starts_at))
            const onlyIds = listToDelete.map(({id}) => id);
            await deleteTeacherAvailabilities(onlyIds)
        }
        if (toAdd.length) {
            await addTeacherAvailabilities(toAdd)
        }

        setLoading(false)
        setChosenDay(DateTime.local().startOf('day'))
        close()
    }

    function close() {
        resetToAdd()
        resetToRemove()
        setModalActive(!modalActive)
        setChosenDay(DateTime.local().startOf('day'))
    }

    return (
        <Modal active={modalActive} onClickClose={close} className="max-w-screen-lg w-full lg:p-16 overflow-y-scroll">
            <h2 className="mb-8 text-3xl font-titles">{t('teacher.account.availabilityModal.availability')}</h2>
            <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 lg:w-5/12 py-4">
                    <h3 className="font-semibold font-titles text-xl mb-6">{t('teacher.account.availabilityModal.selectDate')}</h3>
                    <div className="date-picker inline">
                        <DatePicker
                            isInline
                            fetchAvailabilities={fetchAvailabilities}
                            chosenDay={chosenDay}
                            onChange={setChosenDay}
                        />
                    </div>
                </div>
                <div
                    className="md:border-l border-grey-300 md:pl-8 lg:pl-16 w-full md:w-6/12 lg:w-7/12 py-4">
                    <h3 className="font-semibold font-titles text-xl mb-6">{t('teacher.account.availabilityModal.selectTimeslot')}</h3>
                    <TimeslotsTeacher
                        chosenDay={chosenDay}
                    />
                </div>
            </div>
            <SubmitButton isLoading={loading} onClick={onSubmit}>{t('teacher.account.availabilityModal.save')}</SubmitButton>
        </Modal>
    )
}
