import React, { useContext } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import Clock from 'src/components/icons/Clock'
import Calendar from 'src/components/icons/Calendar'
import Badge from 'src/components/atoms/Badge'
import Zoom from "../../../icons/Zoom";
import {Context as ProfileContext} from "../../../../context/ProfileContext";

export default function Lesson({ lesson, onClickReschedule }) {
    const { t, i18n } = useTranslation();
    const {state: {profile}} = useContext(ProfileContext);

    function checkIfRescheduleIsPossible(status, rescheduledFrom) {
        if(!rescheduledFrom) {
            return status === 'confirmed';
        }

        return false
    }

    function checkIfZoomIsEnabled() {
        return profile.integrations.find((integration) => integration.provider === 'zoom')
    }

    function getZoomStartLink() {
        return lesson.booking.meeting ? lesson.booking.meeting.start_url : null
    }

    return (
        <div className="my-6 border border-grey-500 rounded-sm p-4 lg:p-8">
            <div className="flex lg:items-center flex-start">
                <div className="flex-1">
                    <div className="flex items-center justify-between flex-wrap">
                        <h2 className="text-2xl lg:text-3xl font-titles font-bold text-dark mr-3">{`${lesson.booking.student.first_name} ${lesson.booking.student.last_name}`}</h2>
                        {['canceled'].includes(lesson.status.name) && (
                            <Badge
                                className="mb-2"
                                type={lesson.status.name === 'canceled' ? 'error' : 'success'}
                            >{t(`student.profile.lesson.${lesson.status.name}`)}</Badge>
                        )}
                        {lesson.rescheduled_from && (
                            <Badge
                                className="mb-2"
                                type={'success'}
                            >{t(`teacher.account.upcomingLessons.rescheduled`)}</Badge>
                        )}
                        {checkIfRescheduleIsPossible(lesson.status.name, lesson.rescheduled_from) &&
                        <div className="w-auto">
                            <a className={`cursor-pointer font-semibold underline ${lesson.status.name === 'canceled' ? 'text-dark-blue-600' : 'text-grey-600'}`}
                               onClick={() => onClickReschedule(lesson)}>{t('student.profile.lesson.reschedule')}</a>
                        </div>
                        }
                    </div>
                    <div className="flex flex-wrap w-full">
                        <div className="flex-1">
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.booking.instrument.translations[i18n.language]}</p>
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.booking.student.experience_level}</p>
                            <p className="md:inline-block text-grey-800 mr-4">{lesson.progression}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center pt-8">
                <div className="mr-8 inline-flex items-center">
                    <Calendar color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl').toFormat('LLL dd yyyy')}</span>
                </div>
                <div className="inline-flex items-center">
                    <Clock color={'#82898D'} />
                    <span className="pl-2 text-grey-800">{DateTime.fromISO(lesson.starts_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')} - {DateTime.fromISO(lesson.ends_at, {zone: 'gmt'}).setLocale('nl').toFormat('HH:mm')}</span>
                </div>
            </div>
            {lesson.booking.comments !== "" &&
                <div className="w-10/12 mt-8">
                    <p className="text-xs">{t('teacher.account.upcomingLessons.comment')}</p>
                    <p className="text-grey-800 mt-2">{lesson.booking.comments}</p>
                </div>
            }
            <div className="mt-8">
                {!checkIfZoomIsEnabled &&
                    <p className="font-semibold text-dark-blue-600">{t('teacher.account.upcomingLessons.connectZoom')}</p>
                }
                {(checkIfZoomIsEnabled && !getZoomStartLink()) &&
                    <p className="font-semibold text-dark-blue-600">{t('teacher.account.upcomingLessons.zoomLinkIn30')}</p>
                }
                {(checkIfZoomIsEnabled && getZoomStartLink()) &&
                <a
                    href={getZoomStartLink()}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="font-semibold bg-zoom py-4 px-6 text-white inline-block"
                >
                    <div className="flex items-center">
                        <Zoom w={24}/>
                        <span className="pl-3">
                                {t('teacher.account.upcomingLessons.zoomActivated', {
                                    teacher: `${lesson.booking.student.first_name} ${lesson.booking.student.last_name}`,
                                    start_lesson: '...'
                                })}
                            </span>
                    </div>
                </a>
                }
            </div>
        </div>
    )
}
