import React from 'react'
import { useTranslation } from 'react-i18next'
import Separator from 'src/components/atoms/Separator'
import Calendar from 'src/components/icons/Calendar'

export default function Availability({openModal}) {
    const { t } = useTranslation();

    return (
        <>
            <div className="w-full p-10 bg-grey-300 flex justify-between" onClick={openModal}>
                <div className="flex items-center">
                    <Calendar color="rgba(36, 48, 94, 1)"/>
                    <span className="ml-3">{t('teacher.account.availabilityModal.manageAvailability')}</span>
                </div>
                <span className="text-dark-blue-600 hover:underline cursor-pointer">{t('teacher.account.availabilityModal.edit')}</span>
            </div>
            <Separator/>
        </>
    )
}
