import React from 'react'

export default function SignOut({ color }) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill={color || '#BBC2C6'} cursor="pointer">
        <path d="M5.9,0.8c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.3,0.8-0.7,0.8l-0.1,0H3C2.7,2.4,2.4,2.6,2.4,3
            l0,0.1v10c0,0.3,0.2,0.6,0.6,0.6l0.1,0h2.8c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.3,0.8-0.7,0.8l-0.1,0H3c-1.2,0-2.2-0.9-2.2-2.1l0-0.1V3
            c0-1.2,0.9-2.2,2.1-2.2l0.1,0H5.9z M15.2,7.8L15.2,7.8L15.2,7.8L15.2,7.8L15.2,7.8l0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0l-3.7,3.5c-0.3,0.3-0.8,0.3-1.1,0
            c-0.3-0.3-0.3-0.8,0-1.1l0.1-0.1l2.2-2.1l-6.5,0c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.3-0.8,0.7-0.8l0.1,0l6.5,0l-2.2-2.1
            C9.8,4.6,9.8,4.1,10,3.8l0.1-0.1c0.3-0.3,0.7-0.3,1.1-0.1l0.1,0.1l3.7,3.5l0,0c0,0,0,0,0,0.1l-0.1-0.1c0,0,0.1,0.1,0.1,0.1
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0C15.2,7.7,15.2,7.7,15.2,7.8L15.2,7.8
            C15.2,7.8,15.2,7.8,15.2,7.8z" />
    </svg>
  )
}
