import React, {useContext, useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import classNames from 'classnames'
import {Context as AddAvailability} from 'src/context/AddAvailabilityContext';
import {Context as RemoveAvailability} from 'src/context/RemoveAvailabilityContext';

const ACTIVE = "p-4 cursor-pointer text-sm text-center bg-dark-blue-600 text-white";
const DEFAULT = "p-4 cursor-pointer text-sm text-center bg-grey-300";
const NOW = DateTime.local();

export default function Slot(props) {
    const {
        time,
        allActive,
        chosenDay,
    } = props;

    const {state: {toRender, existing}, incrementAvailability, reduceAvailability} = useContext(AddAvailability);
    const {state, toBeRemoved, undo} = useContext(RemoveAvailability);
    const [highlight, setHighlight] = useState(allActive);
    const [visible, setVisible] = useState(true);
    const [slotTime, setSlotTime] = useState(null);
    const [formatedSlot, setFormatedSlot] = useState(null);

    const splitTime = time.split(':');

    // If a whole segment is chosen
    useEffect(() => {
        changeAvailability()
    }, [allActive])

    function changeAvailability() {
        //Add
        if (!toRender.includes(formatedSlot)) {
            incrementAvailability(formatedSlot)
        }
        if (state.includes(formatedSlot)) {
            undo(formatedSlot)
        }
        //Remove
        if (toRender.includes(formatedSlot)) {
            reduceAvailability(formatedSlot)
            if (existing.includes(formatedSlot)) {
                toBeRemoved(formatedSlot)
            }
        }
    }

    // Add or remove highlight effect if the given daytime slot is included in the selected timeslots
    useEffect(() => {
        const slot = chosenDay.set({hour: splitTime[0], minute: splitTime[1]});
        const formated = slot.toISO().split('.')[0] + '+00:00';
        const onTime = slot >= NOW;
        setSlotTime(slot)
        setFormatedSlot(formated)
        setVisible(onTime)
        if (toRender.length && toRender.includes(formated)) {
            return setHighlight(true)
        }
        return setHighlight(false)

    }, [toRender, chosenDay]);

    return (
        <>
            {visible &&
            <span
                className={classNames(highlight ? ACTIVE : DEFAULT)}
                onClick={changeAvailability}>
                {slotTime && slotTime.toFormat('HH:mm')}
            </span>
            }
        </>
    )
}
