import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Availability from 'src/components/sections/teacher/profile/Availability'
import TeacherAddDetailsForm from 'src/components/register/TeacherAddDetailsForm'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import Success from 'src/components/forms/Success'
import EditTeacherForm from "../../../profile/EditTeacherForm";
import {useVisitorApi} from "../../../../hooks/useApi";

export default function EditAccount() {
    const { t } = useTranslation();
    const [successMsg, setSuccessMsg] = useState(false);
    const { state: { profile } } = useContext(ProfileContext);
    const { getMe } = useVisitorApi()

    function onCompletion() {
        setSuccessMsg(true);
        getMe()
        setTimeout(() => setSuccessMsg(false), 3000)
    }

    return (
        <div>
            <h2 className="w-full lg:w-auto mb-2 lg:mb-4 font-bold font-titles text-dark text-2xl lg:text-4xl">{t('teacher.account.nav.editAccount')}</h2>
            <div className="mx-0">
                {profile.email_verified_at ?
                    <div className="lg:w-10/12">
                        <EditTeacherForm onCompletion={onCompletion} formId="edit-student"/>
                    </div> :
                    <div className="lg:w-10/12">
                        <p>{t('student.addDetails.verify.text')}</p>
                    </div>
                }
                {successMsg && <Success>{t('teacher.addDetails.detailsSaved')}</Success>}
            </div>
        </div>
    )
}
