import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ZoomButton } from 'src/components/buttons/Primary'
import Zoom from 'src/components/icons/Zoom'
import Check from 'src/components/icons/Check'
import { useVisitorApi } from "../../../../hooks/useApi";
import useLocalizedRoutes from "../../../../hooks/useLocalizedRoutes";
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import {Context as ProfileContext} from "../../../../context/ProfileContext";

export default function ConnectZoom() {
    const { t } = useTranslation();
    const [ connected, setConnected ] = useState(false);
    const { connectZoomAccount, getMe } = useVisitorApi();
    const { teacherAccount } = useLocalizedRoutes();
    const {state: {profile}} = useContext(ProfileContext);
    const location = useLocation();

    useEffect(function getUser(){
        getMe()
    }, []);

    function checkIfZoomIsEnabled() {
        return profile.integrations.find((integration) => integration.provider === 'zoom')
    }

    function authorizeZoom (){
        connectZoomAccount({redirect_url: `${location.origin + teacherAccount}`}).then(response => {
            navigate(response.data.message)
        })
    }

    return (
        <div>
            <h2 className="w-full md:w-auto mb-2 font-bold lg:mb-0 font-titles text-dark text-2xl lg:text-4xl">
                {t('teacher.account.zoom.title')}
            </h2>
            <div className="mt-8">
                {checkIfZoomIsEnabled() ? (
                    <div className="flex items-center">
                        <Check width={16} color={'#388e3c'} />
                        <p className="pl-3 text-success text-lg">{t('teacher.account.zoom.connected')}</p>
                    </div>
                ) : (
                    <ZoomButton onClick={() => authorizeZoom()}>
                        <div className="flex items-center">
                            <Zoom w={24} />
                            <span className="pl-3">
                                {t('teacher.account.zoom.connect')}
                            </span>
                        </div>
                    </ZoomButton>
                )}
            </div>
        </div>
    )
}
