import React from 'react'

export default function Check({ color, width, height }) {
  return (
    <svg width={width || 10} height={width * .7 || 7} viewBox="0 0 10 7" fill={color || '#fff'}>
        <path d="M8.6,0.6c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7L3.9,6.9C3.7,7,3.3,7,3.1,6.9L0.6,4.4
	        c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l2.1,2.1L8.6,0.6z"/>
    </svg>
  )
}
