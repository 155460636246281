import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import classNames from 'classnames'
import {teacherAvailableTimeSlots} from 'src/data/listData'
import Slot from 'src/components/atoms/Slot'

const HEADER = "w-full bg-grey-300 my-4 p-4 text-center cursor-pointer";
const MORNING = DateTime.local().set({hour: 12});
const AFTERNOON = DateTime.local().set({hour: 18});
const EVENING = DateTime.local().set({hour: 22});

export default function TimeslotsTeacher(props) {
    const {t} = useTranslation();

    const {
        timeslotsToRender,
        chosenDay,
        addAvailability,
        removeAvailability
    } = props;

    const [morningActive, setMorningActive] = useState(false);
    const [afternoonActive, setAfternoonActive] = useState(false);
    const [eveningActive, setEveningActive] = useState(false);
    const [isMorningTooLate, setIsMorningTooLate] = useState(MORNING < chosenDay);
    const [isAfternoonTooLate, setIsAfternoonTooLate] = useState(AFTERNOON < chosenDay);
    const [isEveningTooLate, setIsEveningTooLate] = useState(EVENING < chosenDay);

    // In today's date, only show slots that are after now
    useEffect(() => {
        if(chosenDay.toISODate() === MORNING.toISODate()){
            const morningEval = MORNING.toISO() < chosenDay.toISO();
            const afternoonEval = AFTERNOON.toISO() < chosenDay.toISO();
            const eveningEval = EVENING.toISO() < chosenDay.toISO();

            setIsMorningTooLate(morningEval)
            setIsAfternoonTooLate(afternoonEval)
            setIsEveningTooLate(eveningEval)
        }
    },[chosenDay])

    return (
        <>
            {/*Morning*/}
            {!isMorningTooLate &&
            <>
                <div
                    className={classNames(HEADER)}
                    onClick={() => setMorningActive(!morningActive)}>
                    {t('teacher.account.availabilityModal.morning')}
                </div>
                <div className="grid grid-cols-4 gap-4">
                    {teacherAvailableTimeSlots.morning.map((time, index) => (
                        <Slot
                            time={time}
                            key={index}
                            allActive={morningActive}
                            chosenDay={chosenDay}
                            addAvailability={addAvailability}
                            timeslotsSelected={timeslotsToRender}
                            removeAvailability={removeAvailability}/>
                    ))}
                </div>
            </>
            }

            {/*Afternoon*/}
            {!isAfternoonTooLate &&
            <>
                <div
                    className={classNames(HEADER)}
                    onClick={() => setAfternoonActive(!afternoonActive)}>
                    {t('teacher.account.availabilityModal.afternoon')}
                </div>
                <div className="grid grid-cols-4 gap-4">
                    {teacherAvailableTimeSlots.afternoon.map((time, index) => (
                        <Slot
                            time={time}
                            key={index}
                            allActive={afternoonActive}
                            addAvailability={addAvailability}
                            timeslotsSelected={timeslotsToRender}
                            removeAvailability={removeAvailability}
                            chosenDay={chosenDay}/>
                    ))}
                </div>
            </>
            }

            {/*Evening*/}
            {!isEveningTooLate &&
            <>
                <div
                    className={classNames(HEADER)}
                    onClick={() => setEveningActive(!eveningActive)}>
                    {t('teacher.account.availabilityModal.evening')}
                </div>
                <div className="grid grid-cols-4 gap-4">
                    {teacherAvailableTimeSlots.evening.map((time, index) => (
                        <Slot
                            time={time}
                            key={index}
                            allActive={eveningActive}
                            addAvailability={addAvailability}
                            timeslotsSelected={timeslotsToRender}
                            removeAvailability={removeAvailability}
                            chosenDay={chosenDay}/>
                    ))}
                </div>
            </>
            }
        </>
    )
}
