import React from 'react'

export default function Edit({ color }) {
  return (
    <svg width={12} height={11} viewBox="0 0 12 11" fill={color || '#24305E'} cursor="pointer">
        <path d="M5.6,0.8c0.3,0,0.6,0.3,0.6,0.6C6.2,1.8,6,2,5.7,2L5.6,2H2.2C2,2,1.8,2.2,1.8,2.4l0,0v6.8
            c0,0.2,0.1,0.4,0.3,0.4l0,0H9c0.2,0,0.4-0.1,0.4-0.3l0,0V5.8c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.5l0,0.1v3.4
            c0,0.8-0.7,1.5-1.5,1.6l-0.1,0H2.2c-0.8,0-1.5-0.7-1.6-1.5l0-0.1V2.4c0-0.8,0.7-1.5,1.5-1.6l0.1,0H5.6z M8.6,0.5
            c0.6-0.6,1.7-0.6,2.3,0c0.6,0.6,0.6,1.7,0,2.3l0,0L6.4,7.4C6.3,7.5,6.2,7.5,6.1,7.5l0,0L4.1,8C3.7,8.1,3.3,7.7,3.4,7.3l0,0l0.5-1.9
            c0-0.1,0.1-0.2,0.2-0.3l0,0L8.6,0.5z M10.1,1.4c-0.2-0.2-0.4-0.2-0.6,0l0,0L5,5.8L4.8,6.6l0.8-0.2L10.1,2
            C10.2,1.8,10.2,1.6,10.1,1.4L10.1,1.4L10.1,1.4z" />
    </svg>
  )
}
